import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import LogoIcon from '../img/inline/logo-icon.svg'
import LogoText from '../img/inline/logo-text.svg'
import Github from '../img/inline/github.svg'
import Twitter from '../img/inline/twitter.svg'
import LinkedIn from '../img/inline/linkedin.svg'

const Footer = () => (
    <footer className={cx('bg-gray-900 text-gray-300 px-32 pt-60 pb-32')}>
        <Link
            to="/"
            className={cx('flex items-center justify-center text-white mb-32')}
            data-track-gtm="Footer Navigation|Logo Homepage"
        >
            <span className={cx('sr-only')}>Beacon Biosignals</span>
            <LogoIcon className={cx('rect-logo-footer mr-28')} />
            <LogoText className={cx('rect-logo-text-footer')} />
        </Link>

        <nav className={cx('mb-32')}>
            <ul className={cx('flex justify-center items-center')}>
                <li className={cx('mx-10')}>
                    <a
                        href="https://github.com/beacon-biosignals"
                        className={cx(
                            'block text-white duration-200 hover:text-green-400'
                        )}
                        data-track-gtm="Footer Navigation|Github|https://github.com/beacon-biosignals"
                    >
                        <span className={cx('sr-only')}>Github</span>
                        <Github className={cx('rect-social-icon')} />
                    </a>
                </li>
                <li className={cx('mx-10')}>
                    <a
                        href="https://twitter.com/biosignals"
                        className={cx(
                            'block text-white duration-200 hover:text-green-400'
                        )}
                        data-track-gtm="Footer Navigation|Twitter|https://twitter.com/biosignals"
                    >
                        <span className={cx('sr-only')}>Twitter</span>
                        <Twitter className={cx('rect-social-icon')} />
                    </a>
                </li>
                <li className={cx('mx-10')}>
                    <a
                        href="https://www.linkedin.com/company/beacon-biosignals/"
                        className={cx(
                            'block text-white duration-200 hover:text-green-400'
                        )}
                        data-track-gtm="Footer Navigation|LinkedIn|https://www.linkedin.com/company/beacon-biosignals/"
                    >
                        <span className={cx('sr-only')}>LinkedIn</span>
                        <LinkedIn className={cx('rect-social-icon')} />
                    </a>
                </li>
            </ul>
        </nav>

        <div
            className={cx(
                'flex items-center justify-center flex-wrap text-14 text-center'
            )}
        >
            <p className={cx('px-24 sm-d:w-full')}>
                © {new Date().getFullYear()} Beacon Biosignals, Inc.
            </p>
            <nav>
                <ul className={cx('flex items-center')}>
                    <li>
                        <a
                            href="https://app.beacon.bio/privacy"
                            className={cx('block px-24 hover:underline')}
                            data-track-gtm="Footer Navigation|Privacy Policy"
                        >
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://app.beacon.bio/terms"
                            className={cx('block px-24 hover:underline')}
                            data-track-gtm="Footer Navigation|Terms of Use"
                        >
                            Terms of Use
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
)

export default Footer
