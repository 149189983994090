const plugins = require('@viget/tailwindcss-plugins')
const {
    remPair,
    rem,
    em,
    pxPair,
} = require('@viget/tailwindcss-plugins/utilities/fns')

module.exports = {
    purge: false,
    target: 'browserslist',
    prefix: '',
    important: false,
    separator: ':',
    theme: {
        extend: {
            screens: {
                sm: em(640),
                md: em(768),
                lg: em(1024),
                xl: em(1280),
                'sm-d': { max: em(639) },
                'md-d': { max: em(767) },
                'lg-d': { max: em(1023) },
                'xl-d': { max: em(1279) },
            },
            colors: {
                transparent: 'transparent',
                black: '#000',
                white: '#fff',
                gray: {
                    100: '#f4f4f4',
                    300: '#a5bace',
                    500: '#667a8C',
                    700: '#3a4651',
                    900: '#212930',
                },
                green: {
                    400: '#1fdcb3',
                    500: '#01b68c',
                },
                blue: {
                    300: '#14bbff',
                    400: '#3f66e6',
                    500: '#4671ff',
                    700: '#043183',
                    900: '#03233f',
                },
                pewter: {
                    100: '#F7F7FF',
                    200: '#C3C3DD',
                    500: '#585889',
                    700: '#292951',
                },
                purple: {
                    200: '#8385e6',
                    500: '#7664fc',
                    700: '#4d3bcd',
                    800: '#4534c0',
                    900: '#3e2bc5',
                },
                pink: {
                    500: '#ff2567',
                },
                teal: {
                    500: '#30EBC1',
                    600: '#1FDCB3',
                },
            },
            spacing: {
                px: '1px',
                '0': '0',
                ...remPair(4),
                ...remPair(8),
                ...remPair(10),
                ...remPair(12),
                ...remPair(16),
                ...remPair(18),
                ...remPair(20),
                ...remPair(24),
                ...remPair(28),
                ...remPair(30),
                ...remPair(32),
                ...remPair(36),
                ...remPair(40),
                ...remPair(48),
                ...remPair(56),
                ...remPair(60),
                ...remPair(64),
                ...remPair(80),
                ...remPair(90),
                ...remPair(100),
                ...remPair(120),
                ...remPair(128),
                ...remPair(150),
                ...remPair(180),
                ...remPair(250),
            },
            animation: {
                'slide-right': 'slide-in-right 0.4s ease-out forwards',
                'slide-left': 'slide-in-left 0.4s ease-out forwards',
                'slide-up': 'slide-up 0.25s ease-out forwards',
                'gradient-background':
                    'gradient-purple-sky 0.25s ease-out forwards',
            },
            aspectRatio: {
                '16/9': [16, 9],
            },
            background: (theme) => ({
                hero: `${theme(
                    'colors.purple.900'
                )} url(../img/hero.png) 100% 0 no-repeat`,
                partnerships: `${theme(
                    'colors.purple.900'
                )} url(../img/bg-partnerships.png) 50% / cover no-repeat`,
                'partnerships-md': `${theme(
                    'colors.purple.900'
                )} url(../img/bg-partnerships-md.png) 50% / cover no-repeat`,
                'resources-pub-even': `${theme(
                    'colors.blue.900'
                )} url(../img/bg_sp_1.png) 50% / cover no-repeat`,
                'resources-pub-odd': `${theme(
                    'colors.blue.900'
                )} url(../img/bg_sp_2.png) 50% / cover no-repeat`,
                'resources-code': `${theme(
                    'colors.blue.900'
                )} url(../img/bg_code.png) bottom right / contain no-repeat`,
            }),
            backgroundClip: {
                text: 'text',
            },
            backgroundColor: (theme) => theme('colors'),
            backgroundOpacity: (theme) => theme('opacity'),
            backgroundPosition: {
                bottom: 'bottom',
                center: 'center',
                'center-bottom': 'center bottom',
                left: 'left',
                'left-bottom': 'left bottom',
                'left-top': 'left top',
                right: 'right',
                'right-bottom': 'right bottom',
                'right-top': 'right top',
                top: 'top',
            },
            backgroundSize: {
                auto: 'auto',
                cover: 'cover',
                contain: 'contain',
                'gradient-animation': '400% 400%',
            },
            borderColor: (theme) => ({
                ...theme('colors'),
                default: theme('colors.gray.300', 'currentColor'),
            }),
            borderOpacity: (theme) => theme('opacity'),
            borderRadius: {
                none: '0',
                ...remPair(6),
                ...remPair(8),
                ...remPair(16),
                ...remPair(24),
                full: '9999px',
            },
            borderWidth: {
                default: '1px',
                '0': '0',
                ...pxPair(2),
                ...pxPair(4),
                ...pxPair(8),
            },
            boxShadow: (theme) => ({
                default:
                    '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                md:
                    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                lg:
                    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                xl:
                    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
                inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
                outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
                input: `0 4px 0 0 ${theme('colors.green.400')}`,
                textarea: `0 0 0 4px ${theme('colors.green.400')}`,
                underline: `0 2px 0 0 ${theme('colors.green.400')}`,
                none: 'none',
            }),
            container: {},
            cursor: {
                auto: 'auto',
                default: 'default',
                pointer: 'pointer',
                wait: 'wait',
                text: 'text',
                move: 'move',
                'not-allowed': 'not-allowed',
            },
            fill: {
                current: 'currentColor',
            },
            flex: {
                '1': '1 1 0%',
                auto: '1 1 auto',
                initial: '0 1 auto',
                none: 'none',
            },
            flexGrow: {
                '0': '0',
                default: '1',
            },
            flexShrink: {
                '0': '0',
                default: '1',
            },
            fontFamily: {
                sans: [
                    '"Gotham A"',
                    '"Gotham B"',
                    'Helvetica',
                    'Arial',
                    'sans-serif',
                ],
            },
            fontSize: {
                ...remPair(12),
                ...remPair(14),
                ...remPair(16),
                ...remPair(18),
                ...remPair(20),
                ...remPair(21),
                ...remPair(24),
                ...remPair(28),
                ...remPair(32),
                ...remPair(40),
                ...remPair(48),
                ...remPair(64),
            },
            fontStyle: {
                italic: 'italic',
            },
            fontWeight: {
                hairline: '100',
                thin: '200',
                light: '300',
                normal: '400',
                medium: '500',
                semibold: '600',
                bold: '700',
                extrabold: '800',
                black: '900',
            },
            gap: (theme) => theme('spacing'),
            gradient: (theme) => ({
                'purple-sky': `235deg, ${theme('colors.gray.100')}, ${theme(
                    'colors.purple.900'
                )}`,
                'seafoam-glow': `180deg, ${theme(
                    'colors.green.400'
                )} 0%, ${theme('colors.blue.300')} 100%`,
                'ocean-berry': `180deg, ${theme(
                    'colors.green.500'
                )} 0%, ${theme('colors.purple.700')} 100%`,
                'teal-to-violet': `90deg, ${theme(
                    'colors.green.400'
                )} 0%, ${theme('colors.purple.500')} 100%`,
            }),
            gridTemplateColumns: {
                none: 'none',
                '1': 'repeat(1, minmax(0, 1fr))',
                '2': 'repeat(2, minmax(0, 1fr))',
                '3': 'repeat(3, minmax(0, 1fr))',
                '4': 'repeat(4, minmax(0, 1fr))',
                '5': 'repeat(5, minmax(0, 1fr))',
                '6': 'repeat(6, minmax(0, 1fr))',
                '7': 'repeat(7, minmax(0, 1fr))',
                '8': 'repeat(8, minmax(0, 1fr))',
                '9': 'repeat(9, minmax(0, 1fr))',
                '10': 'repeat(10, minmax(0, 1fr))',
                '11': 'repeat(11, minmax(0, 1fr))',
                '12': 'repeat(12, minmax(0, 1fr))',
            },
            gridColumn: {
                auto: 'auto',
                'span-1': 'span 1 / span 1',
                'span-2': 'span 2 / span 2',
                'span-3': 'span 3 / span 3',
                'span-4': 'span 4 / span 4',
                'span-5': 'span 5 / span 5',
                'span-6': 'span 6 / span 6',
                'span-7': 'span 7 / span 7',
                'span-8': 'span 8 / span 8',
                'span-9': 'span 9 / span 9',
                'span-10': 'span 10 / span 10',
                'span-11': 'span 11 / span 11',
                'span-12': 'span 12 / span 12',
            },
            gridColumnStart: {
                auto: 'auto',
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10',
                '11': '11',
                '12': '12',
                '13': '13',
            },
            gridColumnEnd: {
                auto: 'auto',
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10',
                '11': '11',
                '12': '12',
                '13': '13',
            },
            gridTemplateRows: {
                none: 'none',
                '1': 'repeat(1, minmax(0, 1fr))',
                '2': 'repeat(2, minmax(0, 1fr))',
                '3': 'repeat(3, minmax(0, 1fr))',
                '4': 'repeat(4, minmax(0, 1fr))',
                '5': 'repeat(5, minmax(0, 1fr))',
                '6': 'repeat(6, minmax(0, 1fr))',
            },
            gridRow: {
                auto: 'auto',
                'span-1': 'span 1 / span 1',
                'span-2': 'span 2 / span 2',
                'span-3': 'span 3 / span 3',
                'span-4': 'span 4 / span 4',
                'span-5': 'span 5 / span 5',
                'span-6': 'span 6 / span 6',
            },
            gridRowStart: {
                auto: 'auto',
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
            },
            gridRowEnd: {
                auto: 'auto',
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
            },
            heading: {
                jbb: {
                    size: rem(48),
                    weight: 'medium',
                    lineHeight: 'tight',
                },
                jb: {
                    size: rem(32),
                    weight: 'medium',
                },
                xl: {
                    size: rem(28),
                    weight: 'medium',
                },
                lg: {
                    size: rem(24),
                    weight: 'medium',
                },
                md: {
                    size: rem(21),
                    weight: 'medium',
                },
                sm: {
                    size: rem(16),
                    weight: 'medium',
                    letterSpacing: 'wide',
                    uppercase: true,
                },
                xs: {
                    size: rem(14),
                    weight: 'medium',
                    letterSpacing: 'wide',
                    uppercase: true,
                },
            },
            height: (theme) => ({
                auto: 'auto',
                ...theme('spacing'),
                full: '100%',
                screen: '100vh',
                animation: '400vh',
                ...remPair(400),
                ...remPair(480),
                ...remPair(640),
                ...remPair(760),
            }),
            inset: {
                '0': '0',
                ...remPair(20),
                ...remPair(52),
                ...remPair(100),
                '1/2': '50%',
                auto: 'auto',
            },
            keyframes: {
                'slide-in-right': {
                    '0%': {
                        opacity: 0,
                        transform: 'translateX(25%)',
                    },
                    '100%': {
                        opacity: 1,
                        transform: 'translateX(0%)',
                    },
                },
                'slide-in-left': {
                    '0%': {
                        opacity: 0,
                        transform: 'translateX(-25%)',
                    },
                    '100%': {
                        opacity: 1,
                        transform: 'translateX(0%)',
                    },
                },
                'slide-up': {
                    '0%': {
                        transform: 'translateY(100%)',
                    },
                    '100%': {
                        transform: 'translateY(0%)',
                    },
                },
                'gradient-purple-sky': {
                    '0%': {
                        backgroundPosition: '100% 0%',
                    },
                    '50%': {
                        backgroundPosition: '100% 50%',
                    },
                    '100%': {
                        backgroundPosition: '0% 100%',
                    },
                },
            },
            letterSpacing: {
                tighter: '-0.05em',
                tight: '-0.025em',
                normal: '0',
                wide: '0.02em',
                wider: '0.05em',
                widest: '0.1em',
            },
            lineHeight: {
                none: '1',
                tight: '1.25',
                snug: '1.44',
                normal: '1.5',
                relaxed: '1.6',
                loose: '2',
            },
            listStyleType: {
                none: 'none',
                disc: 'disc',
                decimal: 'decimal',
            },
            margin: (theme, { negative }) => ({
                auto: 'auto',
                ...theme('spacing'),
                ...negative(theme('spacing')),
            }),
            maxHeight: {
                ...remPair(650),
                ...remPair(760),
                full: '100%',
                screen: '100vh',
            },
            maxWidth: {
                none: 'none',
                full: '100%',
                ...remPair(310),
                ...remPair(420),
                ...remPair(600),
                ...remPair(768),
                ...remPair(840),
                ...remPair(1264),
                ...remPair(1440),
            },
            minHeight: {
                '0': '0',
                ...remPair(380),
                ...remPair(400),
                ...remPair(650),
                full: '100%',
                screen: '100vh',
            },
            minWidth: {
                '0': '0',
                ...remPair(180),
                ...remPair(225),
                full: '100%',
            },
            objectPosition: {
                bottom: 'bottom',
                center: 'center',
                left: 'left',
                'left-bottom': 'left bottom',
                'left-top': 'left top',
                right: 'right',
                'right-bottom': 'right bottom',
                'right-top': 'right top',
                top: 'top',
            },
            opacity: {
                '0': '0',
                '25': '0.25',
                '50': '0.5',
                '70': '0.7',
                '75': '0.75',
                '100': '1',
            },
            order: {
                first: '-9999',
                last: '9999',
                none: '0',
            },
            padding: (theme) => theme('spacing'),
            placeholderColor: (theme) => theme('colors'),
            placeholderOpacity: (theme) => theme('opacity'),
            rect: {
                logo: [49, 43],
                'logo-sm': [34, 32],
                'logo-footer': [58, 50],
                'logo-text': [248, 15],
                'logo-text-sm': [171, 13],
                'logo-text-footer': [290, 18],
                hamburger: [24, 12],
                close: [18, 18],
                'nav-button': [48, 48],
                'arrow-submit': [16, 9],
                'social-icon': [36, 36],
                'social-icon-sm': [24, 24],
            },
            rotate: {
                180: '180deg',
                '-90': '-90deg',
            },
            scale: {
                '0': '0',
                '100': '1',
                '-100': '-1',
                '105': '1.05',
            },
            skew: {},
            space: (theme, { negative }) => ({
                ...theme('spacing'),
                ...negative(theme('spacing')),
            }),
            stroke: {
                current: 'currentColor',
            },
            textColor: (theme) => theme('colors'),
            textOpacity: (theme) => theme('opacity'),
            transformOrigin: {
                center: 'center',
                top: 'top',
                'top-right': 'top right',
                right: 'right',
                'bottom-right': 'bottom right',
                bottom: 'bottom',
                'bottom-left': 'bottom left',
                left: 'left',
                'top-left': 'top left',
            },
            transitionDelay: (theme) => theme('transitionDuration'),
            transitionDuration: {
                '200': '200ms',
                '400': '400ms',
            },
            transitionProperty: {
                none: 'none',
                default: 'all',
                opacity: 'opacity',
                transform: 'transform',
            },
            transitionTimingFunction: {
                'in-out': 'cubic-bezier(0.420, 0.000, 0.580, 1.000)',
            },
            translate: (theme, { negative }) => ({
                ...theme('spacing'),
                ...negative(theme('spacing')),
                '-full': '-100%',
                '-1/2': '-50%',
                '1/2': '50%',
                full: '100%',
            }),
            width: (theme) => ({
                auto: 'auto',
                ...theme('spacing'),
                '1/2': '50%',
                '1/3': '33.333333%',
                '2/3': '66.666667%',
                '1/4': '25%',
                '2/4': '50%',
                '3/4': '75%',
                '1/5': '20%',
                '2/5': '40%',
                '3/5': '60%',
                '4/5': '80%',
                '1/6': '16.666667%',
                '2/6': '33.333333%',
                '3/6': '50%',
                '4/6': '66.666667%',
                '5/6': '83.333333%',
                '1/12': '8.333333%',
                '2/12': '16.666667%',
                '3/12': '25%',
                '4/12': '33.333333%',
                '5/12': '41.666667%',
                '6/12': '50%',
                '7/12': '58.333333%',
                '8/12': '66.666667%',
                '9/12': '75%',
                '10/12': '83.333333%',
                '11/12': '91.666667%',
                full: '100%',
                screen: '100vw',
            }),
            zIndex: {
                auto: 'auto',
                '-1': '-1',
                '0': '0',
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
            },
        },
        variants: {
            alignContent: ['responsive'],
            alignItems: ['responsive'],
            alignSelf: ['responsive'],
            animation: ['responsive', 'group-hover', 'group-focus'],
            appearance: ['responsive'],
            aspectRatio: [],
            background: ['responsive'],
            backgroundAttachment: ['responsive'],
            backgroundColor: [
                'responsive',
                'hover',
                'focus',
                'group-hover',
                'group-focus',
            ],
            backgroundClip: ['responsive'],
            backgroundOpacity: ['responsive', 'hover', 'focus'],
            backgroundPosition: ['responsive'],
            backgroundRepeat: ['responsive'],
            backgroundSize: ['responsive', 'group-hover', 'group-focus'],
            borderCollapse: ['responsive'],
            borderColor: ['responsive', 'hover', 'focus'],
            borderOpacity: ['responsive', 'hover', 'focus'],
            borderRadius: ['responsive'],
            borderStyle: ['responsive'],
            borderWidth: ['responsive'],
            boxShadow: ['responsive', 'hover', 'focus', 'group-hover'],
            clear: ['responsive'],
            cursor: ['responsive'],
            display: ['responsive', 'group-hover', 'group-focus'],
            divideColor: ['responsive'],
            divideOpacity: ['responsive'],
            divideWidth: ['responsive'],
            fill: ['responsive'],
            flex: ['responsive'],
            flexDirection: ['responsive'],
            flexGrow: ['responsive'],
            flexShrink: ['responsive'],
            flexWrap: ['responsive'],
            float: ['responsive'],
            fontFamily: ['responsive'],
            fontSize: ['responsive'],
            fontSmoothing: ['responsive'],
            fontStyle: ['responsive'],
            fontWeight: ['responsive', 'hover', 'focus'],
            gap: ['responsive'],
            gradient: ['responsive', 'group-hover', 'group-focus'],
            gridAutoFlow: ['responsive'],
            gridTemplateColumns: ['responsive'],
            gridColumn: ['responsive'],
            gridColumnStart: ['responsive'],
            gridColumnEnd: ['responsive'],
            gridTemplateRows: ['responsive'],
            gridRow: ['responsive'],
            gridRowStart: ['responsive'],
            gridRowEnd: ['responsive'],
            heading: ['responsive'],
            height: ['responsive'],
            inset: ['responsive'],
            justifyContent: ['responsive'],
            letterSpacing: ['responsive'],
            lineHeight: ['responsive'],
            listStylePosition: ['responsive'],
            listStyleType: ['responsive'],
            margin: ['responsive'],
            maxHeight: ['responsive'],
            maxWidth: ['responsive'],
            minHeight: ['responsive'],
            minWidth: ['responsive'],
            objectFit: ['responsive'],
            objectPosition: ['responsive'],
            opacity: ['responsive', 'hover', 'focus', 'group-hover'],
            order: ['responsive'],
            outline: ['responsive', 'focus'],
            overflow: ['responsive'],
            padding: ['responsive'],
            placeholderColor: ['responsive', 'focus'],
            placeholderOpacity: ['responsive', 'focus'],
            pointerEvents: ['responsive'],
            position: ['responsive'],
            rect: ['responsive'],
            resize: ['responsive'],
            rotate: [],
            scale: ['responsive', 'hover', 'focus'],
            skew: [],
            space: ['responsive'],
            stroke: ['responsive'],
            strokeWidth: ['responsive'],
            tableLayout: ['responsive'],
            textAlign: ['responsive'],
            textColor: [
                'responsive',
                'hover',
                'focus',
                'group-hover',
                'group-focus',
            ],
            textDecoration: ['responsive', 'hover', 'focus'],
            textOpacity: ['responsive', 'hover', 'focus'],
            textTransform: ['responsive'],
            transformOrigin: [],
            transitionDelay: ['responsive'],
            transitionDuration: ['responsive'],
            transitionProperty: ['responsive'],
            transitionTimingFunction: ['responsive'],
            translate: ['responsive', 'group-hover', 'group-focus'],
            userSelect: ['responsive'],
            verticalAlign: ['responsive'],
            visibility: ['responsive', 'group-hover', 'group-focus'],
            whitespace: ['responsive'],
            width: ['responsive'],
            wordBreak: ['responsive'],
            zIndex: ['responsive'],
        },
    },
    corePlugins: {
        accessibility: false,
        boxSizing: false,
        container: false,
        preflight: false,
    },
    plugins: [
        plugins.rect,
        plugins.sr,
        plugins.background,
        plugins.gradient,
        require('./config/tailwindcss/heading'),
        require('./config/tailwindcss/backgroundClip'),
        require('tailwindcss-aspect-ratio'),
    ],
}
