const plugin = require('tailwindcss/plugin')

module.exports = plugin(({ addUtilities, e, theme, variants }) => {
    const pluginConfig = theme('backgroundClip', {})
    const pluginVariants = variants('backgroundClip', [])

    const utilities = Object.entries(pluginConfig).map(([name, value]) => ({
        [`.${e(`background-clip-${name}`)}`]: {
            'background-clip': value,
        },
    }))

    addUtilities(utilities, pluginVariants)
})
