import React, { useState } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import PropTypes from 'prop-types'
import NavButton from './NavButton'
import LogoIcon from '../img/inline/logo-icon.svg'
import LogoText from '../img/inline/logo-text.svg'
import ContactButton from './ContactButton'

const links = [
    {
        name: 'Home',
        link: '/',
        className: 'xl:hidden',
    },
    {
        name: 'Products & Services',
        link: '/products-services',
    },
    {
        name: 'Dreem Headband',
        link: '/dreem-headband',
    },
    {
        name: 'Team',
        link: '/our-team',
    },
    {
        name: 'Careers',
        link: '/careers',
    },
    {
        name: 'Resources',
        link: '/resources',
    },
]

const Header = ({ overlappingHeader }) => {
    const [open, setOpen] = useState(false)

    return (
        <header
            className={cx({
                'absolute top-0 left-0 z-2 w-full text-white': overlappingHeader,
            })}
        >
            <div className={cx('max-w-1264 mx-auto px-24 xl:px-32')}>
                <div
                    className={cx(
                        'flex items-center py-24 xl-d:relative xl-d:justify-between xl:py-60 whitespace-no-wrap',
                        {
                            'border-gray-100 border-b xl:border-b-2': !overlappingHeader,
                        }
                    )}
                >
                    <Link
                        to="/"
                        className={cx('flex items-center xl:mr-64')}
                        data-track-gtm="Top Navigation|Logo Homepage"
                    >
                        <span className={cx('sr-only')}>Beacon Biosignals</span>
                        <LogoIcon
                            className={cx(
                                'mr-16 rect-logo-sm md:mr-18 md:rect-logo'
                            )}
                        />
                        <LogoText
                            className={cx(
                                'rect-logo-text-sm md:rect-logo-text'
                            )}
                        />
                    </Link>

                    <NavButton
                        open={open}
                        setOpen={setOpen}
                        overlappingHeader={overlappingHeader}
                    />

                    <nav
                        aria-label="Social Sharing Navigation"
                        className={cx(
                            'flex-grow xl-d:inset-0 xl-d:fixed xl-d:bg-purple-700 xl-d:text-white xl-d:z-2 xl-d:transform xl-d:duration-200 xl-d:ease-in-out xl:transition-none',
                            {
                                'xl-d:translate-x-full xl-d:opacity-0': !open,
                                'xl-d:translate-x-0 xl-d:opacity-100': open,
                            }
                        )}
                    >
                        <ul
                            className={cx(
                                'flex flex-grow items-center text-center xl-d:flex-col xl-d:justify-center xl-d:h-full xl-d:text-32',
                                'xl:justify-end'
                            )}
                        >
                            {links.map(({ name, link, className = null }) => (
                                <li
                                    className={cx(
                                        'xl-d:my-12 xl:subpixel-antialiased',
                                        className
                                    )}
                                    key={name}
                                >
                                    <Link
                                        to={link}
                                        className={cx(
                                            'block px-20 group xl:text-16'
                                        )}
                                        activeClassName={cx('active')}
                                        data-track-gtm={`Top Navigation|${name}`}
                                    >
                                        <span
                                            className={cx(
                                                'duration-200 group-hover:shadow-underline'
                                            )}
                                        >
                                            {name}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                            <li
                                className={cx(
                                    'xl-d:mt-32 xl:flex xl:justify-end xl:flex-grow xl:ml-64'
                                )}
                                data-track-gtm="Top Navigation|Login"
                            >
                                <ContactButton />
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    overlappingHeader: PropTypes.bool,
}

export default Header
