import React, { useState, useMemo, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { ArrowRight } from 'lucide-react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import { X } from 'lucide-react'

import { theme } from '../../tailwind.config'
import Image from '../components/Image'

const Alert = () => {
    const alert =
        useStaticQuery(graphql`
            query AlertQuery {
                markdownRemark(
                    frontmatter: { templateKey: { eq: "alert-notification" } }
                ) {
                    frontmatter {
                        title
                        description
                        url
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1440, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `)?.markdownRemark?.frontmatter ?? null

    const { pathname } = useLocation()
    const cookies = new Cookies()

    const [alertDismissed, setAlertDismissed] = useState(true)

    useEffect(() => {
        const alertCookie = cookies.get('alertDismissed')
        if (cookies.get('alertDismissed') === undefined) {
            cookies.set('alertDismissed', false)
            setAlertDismissed(false)
        } else {
            if (alertCookie === 'false') {
                setAlertDismissed(false)
            }
            if (alertCookie === 'true') {
                setAlertDismissed(true)
            }
        }
    }, [cookies])

    const path = pathname.split('/')[1]

    const showAlert = useMemo(
        () =>
            path !== 'press-releases' &&
            path !== 'blog' &&
            alertDismissed === false,
        [path, alertDismissed]
    )

    const handleSetAlertDismissed = () => {
        cookies.set('alertDismissed', true)
        setAlertDismissed(true)
    }

    return (
        <div>
            {!!alert && showAlert && (
                <AlertNotification
                    alert={alert}
                    handleSetAlertDismissed={handleSetAlertDismissed}
                />
            )}
        </div>
    )
}

export default Alert

const AlertNotification = ({ alert, handleSetAlertDismissed }) => {
    return (
        <div className="bg-teal-500 w-full flex py-20 h-80">
            <div className="max-w-1264 mx-auto px-24 xl:px-32 flex justify-between w-full">
                <div className="flex items-center gap-4 md:gap-32">
                    <Image
                        className="h-40 hidden md:block"
                        image={alert.featuredImage?.childImageSharp?.fluid?.src}
                    />
                    <div className="text-pewter-700 text-12 md:text-16">
                        <span className="whitespace-no-wrap font-semibold mr-4 md:mr-10">
                            {alert.title}
                        </span>
                        <span className="text-green-400 mr-4 md:mr-10">|</span>
                        <span>{alert.description}</span>
                    </div>
                    {alert.url ? (
                        <a
                            href={alert.url}
                            className="border border-pewter-700 border-solid rounded-full mr-12"
                        >
                            <div className="px-16 py-8 flex items-center">
                                <div className="mr-12 text-12 md:text-16 text-pewter-700">
                                    Read More
                                </div>
                                <ArrowRight
                                    size="16px"
                                    color={theme.extend.colors.pewter[700]}
                                    style={{ color: 'transparent' }}
                                />
                            </div>
                        </a>
                    ) : null}
                </div>
                <button
                    className="text-pewter-700"
                    onClick={handleSetAlertDismissed}
                >
                    <X />
                </button>
            </div>
        </div>
    )
}

AlertNotification.propTypes = {
    alert: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        featuredImage: PropTypes.object,
        url: PropTypes.string,
    }),
    handleSetAlertDismissed: PropTypes.func,
}
