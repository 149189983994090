import React from 'react'
import '@lottiefiles/lottie-player'
import cx from 'classnames'

const FooterAnimation = () => {
    return (
        // TODO update src?
        <div className={cx('max-w-1440 mx-auto')}>
            <lottie-player
                class="lottie-player"
                src="https://assets1.lottiefiles.com/private_files/lf30_1gR2hy.json"
                loop
                autoplay
            />
        </div>
    )
}

export default FooterAnimation
