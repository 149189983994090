import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useLocation } from '@reach/router'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FooterAnimation from '../components/FooterAnimation'
import Alert from '../templates/alert-notification'

const TemplateWrapper = ({ children, title, description }) => {
    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
    } = useSiteMetadata()
    const location = useLocation()

    // NOTE: flip description and og:image for takeda press release
    //       https://beacon-biosignals.slack.com/archives/C05RGUMQAUU/p1714479771591879
    const isTakedaPressRelease = location.pathname.includes(
        'beacon-biosignals-and-takeda-launch-strategic-collaboration-to-advance-neurobiomarkers-and-endpoints-for-sleep-disorders'
    )
    const desc = isTakedaPressRelease
        ? 'Collaboration leverages Beacon’s FDA-cleared at-home EEG headband and AI platform to support development of novel precision therapies and improve the diagnostic journey for patients'
        : description
    const ogImage = isTakedaPressRelease
        ? `${withPrefix('/')}img/tak-og-image.jpg`
        : `${withPrefix('/')}img/og-image.png`

    const seo = {
        title: title || defaultTitle,
        description: desc || defaultDescription,
    }

    const isHomePage = location.pathname === '/'
    const isProductsServicesPage = location.pathname.includes(
        'products-services'
    )
    const isBlogPage =
        location.pathname.includes('blog') &&
        !(
            location.pathname.endsWith('blog') ||
            location.pathname.endsWith('blog/')
        )

    return (
        <div>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
                <html lang="en" />
                {seo.description && (
                    <meta name="description" content={seo.description} />
                )}

                {seo.description && (
                    <meta property="og:description" content={seo.description} />
                )}
                {seo.description && (
                    <meta
                        name="twitter:description"
                        content={seo.description}
                    />
                )}

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${withPrefix('/')}img/favicon-32x32.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${withPrefix('/')}img/favicon-16x16.png`}
                />
                <link
                    rel="manifest"
                    href={`${withPrefix('/')}img/site.webmanifest`}
                />
                <link
                    rel="mask-icon"
                    href={`${withPrefix(
                        '/'
                    )}img/safari-pinned-tab.svg" color="#1fdcb3`}
                />
                <link
                    rel="shortcut icon"
                    href={`${withPrefix('/')}img/favicon.ico`}
                />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta
                    name="msapplication-config"
                    content={`${withPrefix('/')}img/browserconfig.xml`}
                />
                <meta name="theme-color" content="#ffffff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content={ogImage} />

                <meta property="og:site_name" content="Beacon Biosignals" />
                <meta property="og:locale" content="en_US" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@biosignals" />
                <meta name="twitter:creator" content="@biosignals" />
                <meta name="twitter:image" content={ogImage} />

                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cloud.typography.com/7033698/6288612/css/fonts.css"
                />
            </Helmet>
            <Alert />
            <div className={cx('relative', { 'overflow-hidden': !isHomePage })}>
                <Header overlappingHeader={isHomePage || isBlogPage} />
                <main>{children}</main>

                {!(isHomePage || isProductsServicesPage) && <FooterAnimation />}
                <Footer />
            </div>
        </div>
    )
}

TemplateWrapper.propTypes = {
    children: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default TemplateWrapper
