import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

export default function ContactButton({ className }) {
    return (
        <a
            href="/contact"
            className={cx(
                className,
                'block bg-blue-500 text-white rounded-6 text-16 font-bold px-20 py-12 duration-200 hover:bg-blue-700 whitespace-no-wrap'
            )}
        >
            Contact Us
        </a>
    )
}

ContactButton.propTypes = {
    className: PropTypes.string,
}
