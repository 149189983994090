const plugin = require('tailwindcss/plugin')

module.exports = plugin(({ addUtilities, theme, variants }) => {
    addUtilities(
        Object.entries(theme('heading')).map(([name, props]) => {
            const {
                size,
                letterSpacing = 'normal',
                lineHeight = 'none',
                weight = 'normal',
                uppercase = false,
            } = props

            const rule = {
                'font-family': theme('fontFamily.sans').join(', '),
                'font-size': size,
                'font-weight': theme(`fontWeight.${weight}`),
                'letter-spacing': theme(`letterSpacing.${letterSpacing}`),
                'line-height': theme(`lineHeight.${lineHeight}`),
            }

            if (uppercase) {
                rule['text-transform'] = 'uppercase'
            }

            return {
                [`.hd-${name}`]: rule,
            }
        }),
        variants('heading', [])
    )
})
