import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Hamburger from '../img/inline/hamburger.svg'
import Close from '../img/inline/close.svg'

const NavButton = ({ open, setOpen, overlappingHeader }) => {
    return (
        <button
            className={cx('xl:hidden p-12 rect-nav-button', {
                'flex items-center justify-center relative z-3': open,
            })}
            open={open}
            onClick={() => setOpen(!open)}
        >
            {open ? (
                <Close className={cx('rect-close text-white')} />
            ) : (
                <Hamburger
                    className={cx('rect-hamburger', {
                        'text-gray-500': !overlappingHeader,
                    })}
                />
            )}
            <span className={cx('sr-only')}>Toggle Navigation</span>
        </button>
    )
}

NavButton.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    overlappingHeader: PropTypes.bool.isRequired,
}

export default NavButton
