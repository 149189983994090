import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Image = ({ image, className, alt = '' }) => {
    if (!!image && !!image.childImageSharp) {
        return (
            <Img
                fluid={image.childImageSharp.fluid}
                className={className}
                alt={alt}
            />
        )
    }

    // svgs
    if (!!image && !!image.publicURL && image.extension === 'svg') {
        return <img src={image.publicURL} className={className} alt={alt} />
    }

    if (!!image && typeof image === 'string') {
        return <img src={image} className={className} alt={alt} />
    }

    return null
}

Image.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    className: PropTypes.string,
    alt: PropTypes.string,
}

export default Image
